import {StyleSheet} from 'react-native';
import {semiBold, regular, screenWidth, isMobile} from '../../utils/constants';

const textColor = '#454F5B';
const buttonColor = '#6B7280';

export const webStyle = {
  uploadButton: {
    marginTop: 12,
    borderWidth: 2,
    padding: 10,
    borderColor: '#525FB8',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
    borderStyle: 'solid',
    display: 'flex',
    justifyContent: 'space-between',
    height: 30,
    cursor: 'pointer',
  },
  uploadText: {
    color: '#525FB8',
    fontFamily: semiBold,
    flex: 1,
  },
};

export const styles = StyleSheet.create({
  container: {
    paddingHorizontal: isMobile ? 20 : 80,
    alignItems: 'center',
  },
  containerSideBar: {
    backgroundColor: '#F5F4F2',
    paddingBottom: 20,
    paddingHorizontal: isMobile ? 20 : 80,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.18,
    shadowRadius: 3,
    position: 'absolute',
    width: isMobile ? '100%' : 440,
    top: 0,
    right: 0,
  },
  wrapper: {
    marginTop: isMobile ? 20 : 40,
  },
  nameSection: {
    backgroundColor: '#fff',
    padding: 16,
    borderRadius: 14,
    marginTop: 20,
  },
  subscriptionTop: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  subscriptionSection: {
    backgroundColor: '#fff',
    padding: 16,
    borderRadius: 14,
    marginTop: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  kycSection: {
    backgroundColor: '#fff',
    padding: 16,
    borderRadius: 14,
    marginTop: 20,
  },
  section: {
    backgroundColor: '#fff',
    paddingHorizontal: 16,
    borderRadius: 14,
    marginTop: 20,
  },
  sectionButton: {
    paddingVertical: 14,
    borderBottomColor: '#F0EFEF',
    borderBottomWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  kycText: {
    fontSize: 16,
    color: '#454F5B',
    fontFamily: regular,
    paddingRight: 10,
  },
  kycFile: {
    fontSize: 12,
    color: '#525FB8',
    fontFamily: regular,
    paddingRight: 10,
    marginTop: 5,
  },
  sectionButtonText: {
    fontSize: 18,
    color: '#454F5B',
    fontFamily: semiBold,
  },
  subscription: {
    fontSize: 16,
    color: '#454F5B',
    fontFamily: semiBold,
  },
  orderName: {
    fontSize: 16,
    color: '#454F5B',
    fontFamily: semiBold,
    marginBottom: 6,
    marginTop: 4,
  },
  orderSection: {
    backgroundColor: '#fff',
    padding: 16,
    borderRadius: 14,
    marginTop: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  name: {
    fontSize: 18,
    color: '#454F5B',
    fontFamily: semiBold,
  },
  smallText: {
    color: '#919EAB',
  },
  rowItem: {
    flexDirection: 'row',
    paddingVertical: 4,
    backgroundColor: '#F6F6F6',
    marginVertical: 2,
    paddingHorizontal: 2,
    minWidth: '100%',
  },
  rowKey: {
    fontFamily: regular,
    fontSize: 14,
    color: '#637381',
    width: '50%',
  },
  rowValue: {
    fontFamily: semiBold,
    fontSize: 14,
    color: '#454F5B',
    width: '50%',
  },
  active: {
    backgroundColor: '#448742',
    paddingHorizontal: 18,
    paddingVertical: 9,
    borderRadius: 18,
  },
  subscribe: {
    backgroundColor: 'white',
    paddingHorizontal: 18,
    paddingVertical: 9,
    borderRadius: 10,
    borderColor: '#31BC98',
    borderWidth: 2,
  },
  subscribeText: {
    color: '#31BC98',
    fontFamily: semiBold,
  },
  activeText: {
    color: 'white',
    fontFamily: semiBold,
  },
  upload: {
    paddingHorizontal: 16,
    paddingVertical: 9,
    borderRadius: 8,
    borderColor: '#31BC98',
    borderWidth: 2,
  },
  uploadText: {
    color: '#31BC98',
    fontFamily: semiBold,
  },
  sectionUpload: {
    backgroundColor: '#fff',
    paddingHorizontal: 16,
    borderRadius: 14,
    marginTop: 20,
    paddingVertical: 20,
    elevation: 4,
  },
  input: {
    height: 40,
    marginTop: 12,
    borderWidth: 1,
    padding: 10,
    borderColor: '#CECECE',
  },
  uploadButton: {
    borderWidth: 2,
    padding: 10,
    borderColor: '#525FB8',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    marginTop: 20,
  },
  uploadButtonText: {
    fontSize: 16,
    color: '#525FB8',
    fontFamily: semiBold,
  },
  verifyButton: {
    backgroundColor: '#525FB8',
    height: 45,
    alignItems: 'center',
    justifyContent: 'center',
    elevation: 4,
    marginTop: 15,
  },
  verifyText: {
    fontSize: 16,
    color: 'white',
    fontFamily: semiBold,
  },
  listing: {
    minWidth: isMobile ? screenWidth : '100%',
    height: 800,
    paddingHorizontal: 10,
    alignSelf: 'center',
  },
  modalContainer: {
    backgroundColor: '#FFF',
    alignSelf: 'center',
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 6},
    shadowOpacity: 0.25,
    shadowRadius: 10,
    elevation: 5,
    padding: 20,
    position: 'absolute',
    top: 80,
    width: isMobile ? '100%' : 500,
  },
  more: {
    backgroundColor: '#CEEAD5',
    width: 60,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 6,
    paddingVertical: 3,
    marginTop: 10,
  },
  moreText: {
    color: '#72A981',
  },

  Card: {
    backgroundColor: 'white',
    width: isMobile ? '100%' : 600,
    height: isMobile ? '100%' : 750,
    elevation: 3,
    borderRadius: 6,
    marginTop: 15,
  },
  Header: {
    backgroundColor: '#F5F7EE',
    paddingHorizontal: 16,
    paddingVertical: 14,
    borderTopEndRadius: 6,
    borderTopStartRadius: 6,
  },
  HeaderText: {
    fontFamily: semiBold,
    fontSize: 16,
  },
  QuestionsGroup: {
    paddingHorizontal: 16,
    paddingVertical: 16,
    flex: 1,
  },
  Question: {
    fontSize: 14,
    fontFamily: semiBold,
    color: textColor,
    marginBottom: 8,
  },
  QuestionForRange: {
    fontSize: 12,
    fontFamily: semiBold,
    color: '#6B7280',
    marginBottom: 4,
  },
  Rating: {
    fontSize: 12,
    fontFamily: regular,
    color: '#6B7280',
  },
  TabButton: {
    paddingHorizontal: 10,
    paddingVertical: 8,
    borderColor: buttonColor,
    borderRadius: 5,
    borderWidth: 2,
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 4,
    marginRight: 6,
  },
  TabButtonActive: {
    paddingHorizontal: 10,
    paddingVertical: 8,
    borderColor: buttonColor,
    borderRadius: 5,
    borderWidth: 2,
    backgroundColor: buttonColor,
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 4,
    marginRight: 6,
  },
  TabButtonText: {
    fontSize: 12,
    fontFamily: semiBold,
    color: buttonColor,
  },
  TabButtonTextActive: {
    fontSize: 12,
    fontFamily: semiBold,
    color: 'white',
  },
  Section: {
    marginVertical: 8,
  },
  RatingSection: {
    marginVertical: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  ProgressIndicator: {
    width: 22,
    height: 22,
    backgroundColor: '#D9D9D9',
    borderRadius: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  ProgressIndicatorActive: {
    width: 22,
    height: 22,
    backgroundColor: 'white',
    borderColor: '#31BC98',
    borderWidth: 2,
    borderRadius: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  ProgressIndicatorDone: {
    width: 22,
    height: 22,
    backgroundColor: '#31BC98',
    borderRadius: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  ProgressIndicatorText: {
    fontFamily: semiBold,
    fontSize: 10,
  },
  ProgressBar: {
    position: 'relative',
    width: '100%',
  },
  ProgressDivider: {
    width: '100%',
    borderTopColor: '#F0F0F1',
    borderTopWidth: 1,
    position: 'absolute',
    top: 10,
  },
  ButtonPrimary: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    backgroundColor: '#525FB8',
    width: 100,
    height: 32,
  },
  ButtonText: {
    color: 'white',
    fontFamily: semiBold,
    fontSize: 14,
  },
  ButtonSecondary: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    backgroundColor: 'white',
    width: 100,
    height: 32,
  },
  ButtonTextSecondary: {
    color: '#525FB8',
    fontFamily: semiBold,
    fontSize: 14,
  },
  ProgressContainer: {flexDirection: 'row', justifyContent: 'space-between'},
});
