import {StatusCodes} from 'http-status-codes';
import validator from 'validator';
import {
  COMMON_API,
  CUSTOMER_API,
  ORDERS_API,
  USER_API,
  IPO_API,
  QIDATA_API,
  TRADES_API,
  GlobalAlert,
  GlobalErrorHandler,
} from '../utils/constants';
import {GlobalPrompt} from '../components/GlobalPrompt';

export const apiService = {
  getCustomerCare: function () {
    return new Promise((resolve, reject) => {
      fetch(COMMON_API + 'customerCare')
        .then(r => r.json())
        .then(phone => resolve(phone.mobile))
        .catch(error => {
          GlobalAlert(error);
          reject(0);
        });
    });
  },

  versionCheck: function () {
    return new Promise((resolve, reject) => {
      fetch(COMMON_API + 'versionCheck?appName=QuickInvest&appVersion=1.4.0')
        .then(r => r.json())
        .then(data => {
          if (data.message !== 'Sucessful') {
            if (data.message === 'forceUpgrade') { resolve(false); }
            else if (data.message === 'recommendUpgrade') {
              GlobalAlert(
                'A new version of this app available. Please update to get better experience',
              );
              resolve(true);
            }
          }
        })
        .catch(error => {
          GlobalErrorHandler(error);
          reject(false);
        });
    });
  },

  newIOI: function (body) {
    return new Promise((resolve, reject) => {
      fetch(ORDERS_API + 'newResponse', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify(body),
      })
        .then(r => r.json())
        .then(response => {
          if ((response?.status !== true) && (response?.message)) { GlobalAlert(response.message); }
          resolve(response.status);
        })
        .catch(error => {
          GlobalErrorHandler(error);
          reject(error);
        });
    });
  },

  getIPODetails: function (id) {
    return new Promise((resolve, reject) => {
      fetch(IPO_API + 'getIPODetails?id=' + id)
        .then(r => r.json())
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          GlobalErrorHandler(error);
          reject(error);
        });
    });
  },

  getBondDetails: function (isinNo) {
    return new Promise((resolve, reject) => {
      fetch(QIDATA_API + 'getBondDetails?isinNo=' + isinNo)
        .then(r => r.json())
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          GlobalErrorHandler(error);
          reject(error);
        });
    });
  },

  validateOtp: function (phoneNo) {
    return new Promise((resolve, reject) => {
      fetch(
        COMMON_API + 'validateMobile?phoneNo=' + encodeURIComponent(phoneNo),
      )
        .then(response => {
          if (response.status === StatusCodes.OK) {
            response.json().then(result => {
              GlobalPrompt(
                'We have sent an otp to your mobile. Kindly input the same.',
                otp => {
                  if (otp && validator.isNumeric(otp) && otp === result.message) { resolve(true); }
                  else { resolve(false); }
                },
              );
            });
          }
        })
        .catch(error => {
          GlobalErrorHandler(error);
          reject(error);
        });
    });
  },

  addCustomer: function (userData) {
    let responseStatus = null;
    return new Promise((resolve, reject) => {
        fetch(CUSTOMER_API + 'upsert', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ data : userData }),
            credentials: 'include',
        })
          .then(r => {r.json() ; responseStatus = r.status})
          .then(result => {
            //Need something better here. If the user exists, this API returns BAD_REQUEST with message Wrong Data
              if ((responseStatus === StatusCodes.OK) ||
                ((responseStatus === StatusCodes.BAD_REQUEST) && (result.message === 'Wrong Data'))){
                resolve(true);
              } else {
                GlobalAlert('Oops! Something went wrong. Please try again.');
                reject(false);
              }
          })
          .catch(error => {
            GlobalErrorHandler(error);
            reject(error);
          });
    });
  },

  applyIPO: function (body) {
    return new Promise((resolve, reject) => {
      fetch(IPO_API + 'ipoApplication', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(body),
      })
        .then(r => r.json())
        .then(response => {
          GlobalAlert(response.message);
          resolve(response.message);
        })
        .catch(error => {
          GlobalErrorHandler(error);
          reject(error);
        });
    });
  },

  sendContactUs: function (body) {
    return new Promise((resolve, reject) => {
      fetch(COMMON_API + 'contactUs', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(body),
      })
        .then(r => r.json())
        .then(response => resolve(response))
        .catch(error => {
          GlobalErrorHandler(error);
          reject(error);
        });
    });
  },

  getQITypes: function () {
    return new Promise((resolve, reject) => {
      fetch(QIDATA_API + 'getTypes')
        .then(r => r.json())
        .then(types => resolve(types))
        .catch(error => {
          GlobalErrorHandler(error);
          reject(error);
        });
    });
  },

  getActiveIPOs: function () {
    return new Promise((resolve, reject) => {
      fetch(IPO_API + 'getActiveIPOs')
        .then(r => r.json())
        .then(data => resolve(data))
        .catch(error => {
          GlobalErrorHandler(error);
          reject(error);
        });
    });
  },

  getActiveBonds: function (filters, bondType) {
    return new Promise((resolve, reject) => {
      const body = {filters, bondType};
      fetch(QIDATA_API + 'getBonds', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(body),
      })
        .then(r => r.json())
        .then(data => resolve(data))
        .catch(error => {
          GlobalErrorHandler(error);
          reject(error);
        });
    });
  },

  login: function (userName, password, token) {
    let responseStatus = null;
    const body = {username: userName};
    if (password && !validator.isEmpty(password)) { body.password = password; }
    else if (token && !validator.isEmpty(token)) { body.token = token; }
    return new Promise((resolve, reject) => {
      fetch(USER_API + 'auth/login', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(body),
        credentials: 'include',
      })
        .then(r => {
          responseStatus = r.status;
          r.json()
            .then(result => {
              result.responseStatus = responseStatus;
              resolve(result);
            })
            .catch(error => {
              GlobalErrorHandler(error);
              reject(null);
            });
        })
        .catch(error => {
          GlobalErrorHandler(error);
          reject(null);
        });
    });
  },

  logout: function () {
    return new Promise((resolve, reject) => {
      fetch(USER_API + 'auth/logout', {method: 'GET', credentials: 'include'})
        .then(response => {
          if (response.status === StatusCodes.OK) { resolve(response); }
          else { GlobalAlert('Oops! Something went wrong. Please try again.'); }
        })
        .catch(error => {
          GlobalErrorHandler(error);
          reject(error);
        });
    });
  },

  reVerifyUser: function (userName) {
    return new Promise((resolve, reject) => {
      fetch(USER_API + 'reVerifyUser', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({username: userName}),
        credentials: 'include',
      })
        .then(r => {
          if (r.status === StatusCodes.OK) {
            GlobalAlert(
              'We have sent an email to you. Kindly follow the instructions to set your password.',
            );
            resolve();
          }
        })
        .catch(error => {
          GlobalErrorHandler(error);
          reject();
        });
    });
  },

  requestLoginOtp: function (userName) {
    return new Promise((resolve, reject) => {
      fetch(USER_API + 'otp?username=' + userName)
        .then(r => r.json())
        .then(data => resolve(data))
        .catch(error => {
          GlobalErrorHandler(error);
          reject(error);
        });
    });
  },

  setNewPassword: function (userName, password, confirmPassword, hash) {
    return new Promise((resolve, reject) => {
      let data = {
        username: userName,
        password: password,
        confirmPassword: confirmPassword,
      };
      if (hash && !validator.isEmpty(hash)) { data.hash = hash; }
      fetch(USER_API + 'newPassword', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data),
        credentials: 'include',
      })
        .then(r => {
          if (r.status === StatusCodes.OK) {
            GlobalAlert('Sucessfully set the new password!');
            resolve(r);
          } else { r.json().then(error => GlobalAlert(error.message)); }
        })
        .catch(error => {
          if (error.response && (error.response.status !== StatusCodes.BAD_REQUEST)){ reject(error); }
          else { GlobalErrorHandler(error); }
        });
    });
  },

  getUserData: function (userName) {
    return new Promise((resolve, reject) => {
      fetch(USER_API + 'userData?userName=' + userName, {
        credentials: 'include',
      })
        .then(r => r.json())
        .then(data => resolve(data))
        .catch(error => {
          reject(error);
        });
    });
  },

  getMyTrades: function () {
    return new Promise((resolve, reject) => {
      fetch(TRADES_API + 'myTrades', {credentials: 'include'})
        .then(r => r.json())
        .then(data => resolve(data))
        .catch(error => {
          GlobalErrorHandler(error);
          reject(error);
        });
    });
  },

  updateRiskProfile: function (userName, riskProfile) {
    return new Promise((resolve, reject) => {
      fetch(CUSTOMER_API + 'updateRiskProfile', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify({username: userName, riskProfile: riskProfile}),
      })
        .then(r => r.json())
        .then(response => {
          if (response.message) { GlobalAlert(response.message); }
          else { GlobalAlert('Oops! Something went wrong. Please try again.'); }
          resolve();
        })
        .catch(error => {
          GlobalErrorHandler(error);
          reject(error);
        });
    });
  },

//   uploadKYC: function (formData) {
//     return new Promise((resolve, reject) => {
//       fetch(KYC_API + 'upload', {
//         method: 'POST',
//         body: formData,
//         credentials: 'include',
//       })
//         .then(result => {
//           if (result && result.status === StatusCodes.OK) {
//             GlobalAlert('Sucessfully updated the file.');
//             resolve();
//           } else
//             result.json().then(error => {
//               GlobalAlert('Oops! Something went wrong. Please try again.');
//               reject(error);
//             });
//         })
//         .catch(error => {
//           GlobalAlert('Oops! Something went wrong. Please try again.');
//           reject(error);
//         });
//     });
//   },
};
