import React from 'react';
import {Svg, Path, Rect} from 'react-native-svg';

export function Plus() {
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <Path d="M8 1V15" stroke="#637381" strokeWidth="2" />
      <Path d="M1 8H15" stroke="#637381" strokeWidth="2" />
    </Svg>
  );
}

export function Minus() {
  return (
    <Svg width="16" height="16" viewBox="0 0 16 2" fill="none">
      <Path d="M1 1H15" stroke="#637381" strokeWidth="2" />
    </Svg>
  );
}

export function Close({color = 'white'}) {
  return (
    <Svg width="14" height="14" viewBox="0 0 14 14" fill="none">
      <Path d="M13 1L1 13" stroke={color} strokeWidth="2" />
      <Path d="M1 1L13 13" stroke={color} strokeWidth="2" />
    </Svg>
  );
}

export function Back({color = '#637381'}) {
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <Path
        d="M15 8H1"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M8 15L1 8L8 1"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export function Check() {
  return (
    <Svg width="18" height="13" viewBox="0 0 18 13" fill="none">
      <Path d="M17 1L6 12L1 7" stroke="#31BC98" strokeWidth="2" />
    </Svg>
  );
}

export function EmptyCheck() {
  return (
    <Svg width="18" height="18" viewBox="0 0 18 18" fill="none">
      <Rect
        x="1"
        y="1"
        width="16"
        height="16"
        rx="3"
        stroke="#D9D8D8"
        strokeWidth="2"
      />
    </Svg>
  );
}

export function ArrowDown() {
  return (
    <Svg width="14" height="8" viewBox="0 0 14 8" fill="none">
      <Path
        d="M1 1L7 7L13 1"
        stroke="#DD9E05"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </Svg>
  );
}

export function ArrowUp() {
  return (
    <Svg width="14" height="8" viewBox="0 0 14 8" fill="none">
      <Path
        d="M13 7L7 0.999999L1 7"
        stroke="#DD9E05"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </Svg>
  );
}

export function ShareIcon() {
  return (
    <Svg width="21" height="23" viewBox="0 0 21 23" fill="none">
      <Path
        d="M16.4 7.41479C18.0568 7.41479 19.4 6.07165 19.4 4.41479C19.4 2.75794 18.0568 1.41479 16.4 1.41479C14.7431 1.41479 13.4 2.75794 13.4 4.41479C13.4 6.07165 14.7431 7.41479 16.4 7.41479Z"
        stroke="#525FB8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M4.39996 14.4148C6.05682 14.4148 7.39996 13.0716 7.39996 11.4148C7.39996 9.75794 6.05682 8.41479 4.39996 8.41479C2.74311 8.41479 1.39996 9.75794 1.39996 11.4148C1.39996 13.0716 2.74311 14.4148 4.39996 14.4148Z"
        stroke="#525FB8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M16.4 21.4148C18.0568 21.4148 19.4 20.0716 19.4 18.4148C19.4 16.7579 18.0568 15.4148 16.4 15.4148C14.7431 15.4148 13.4 16.7579 13.4 18.4148C13.4 20.0716 14.7431 21.4148 16.4 21.4148Z"
        stroke="#525FB8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M6.98999 12.9248L13.82 16.9048"
        stroke="#525FB8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M13.81 5.9248L6.98999 9.9048"
        stroke="#525FB8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export function FilterIcon() {
  return (
    <Svg width="24" height="25" viewBox="0 0 24 25" fill="none">
      <Path
        d="M20.3076 24.055L20.3076 9.1197C19.5904 8.85927 19.0124 8.31468 18.71 7.61415C18.4075 6.91362 18.4075 6.11952 18.71 5.41898C19.0125 4.71845 19.5904 4.17389 20.3076 3.91343L20.3076 1.90125C20.3076 1.57137 20.4836 1.26665 20.7692 1.10181C21.0547 0.936981 21.4067 0.936981 21.6922 1.10181C21.9778 1.26665 22.1538 1.57138 22.1538 1.90125L22.1538 3.91343C22.871 4.17387 23.449 4.71845 23.7514 5.41898C24.0539 6.11952 24.0539 6.91362 23.7514 7.61415C23.4489 8.31468 22.871 8.85924 22.1538 9.1197L22.1538 24.055C22.1538 24.3848 21.9778 24.6896 21.6922 24.8544C21.4067 25.0192 21.0547 25.0192 20.7692 24.8544C20.4836 24.6896 20.3076 24.3848 20.3076 24.055V24.055ZM22.1538 6.51665C22.1538 6.27187 22.0565 6.03698 21.8834 5.8639C21.7104 5.69083 21.4755 5.59358 21.2307 5.59358C20.9859 5.59358 20.751 5.69083 20.578 5.8639C20.4049 6.03698 20.3076 6.27187 20.3076 6.51665C20.3076 6.76142 20.4049 6.99632 20.578 7.16939C20.751 7.34246 20.9859 7.43972 21.2307 7.43972C21.4755 7.43972 21.7104 7.34246 21.8834 7.16939C22.0565 6.99632 22.1538 6.76142 22.1538 6.51665Z"
        fill="#454F5B"
      />
      <Path
        d="M12.9232 1.90125L12.9232 16.8365C13.6404 17.097 14.2184 17.6415 14.5208 18.3421C14.8233 19.0426 14.8233 19.8367 14.5208 20.5372C14.2184 21.2378 13.6404 21.7823 12.9232 22.0428L12.9232 24.055C12.9232 24.3848 12.7472 24.6896 12.4616 24.8544C12.1761 25.0192 11.8241 25.0192 11.5386 24.8544C11.253 24.6896 11.077 24.3848 11.077 24.055L11.077 22.0428C10.3598 21.7823 9.78184 21.2378 9.47939 20.5372C9.17692 19.8367 9.17692 19.0426 9.47939 18.3421C9.78186 17.6415 10.3598 17.097 11.077 16.8365L11.077 1.90125C11.077 1.57138 11.253 1.26666 11.5386 1.10182C11.8242 0.936989 12.1761 0.936989 12.4616 1.10182C12.7472 1.26666 12.9232 1.57139 12.9232 1.90125ZM11.077 19.4396C11.077 19.6843 11.1743 19.9192 11.3474 20.0923C11.5204 20.2654 11.7553 20.3626 12.0001 20.3626C12.2449 20.3626 12.4798 20.2654 12.6529 20.0923C12.8259 19.9192 12.9232 19.6843 12.9232 19.4396C12.9232 19.1948 12.8259 18.9599 12.6529 18.7868C12.4798 18.6138 12.2449 18.5165 12.0001 18.5165C11.7553 18.5165 11.5204 18.6138 11.3474 18.7868C11.1743 18.9599 11.077 19.1948 11.077 19.4396Z"
        fill="#454F5B"
      />
      <Path
        d="M3.69234 1.90139L3.69234 8.52903C4.40959 8.78947 4.98754 9.33405 5.28999 10.0346C5.59246 10.7351 5.59246 11.5292 5.28999 12.2297C4.98752 12.9303 4.40959 13.4748 3.69234 13.7353L3.69234 24.0552C3.69234 24.3851 3.51638 24.6898 3.2308 24.8546C2.94523 25.0195 2.59331 25.0195 2.30773 24.8546C2.02216 24.6898 1.8462 24.3851 1.8462 24.0552L1.8462 13.7353C1.12895 13.4749 0.550997 12.9303 0.248546 12.2297C-0.0539257 11.5292 -0.0539257 10.7351 0.248546 10.0346C0.551018 9.33405 1.12895 8.78949 1.8462 8.52903L1.8462 1.90139C1.8462 1.57152 2.02216 1.26679 2.30773 1.10196C2.59331 0.937124 2.94523 0.937124 3.2308 1.10196C3.51638 1.26679 3.69234 1.57153 3.69234 1.90139ZM1.8462 11.1321C1.8462 11.3769 1.94345 11.6118 2.11653 11.7848C2.2896 11.9579 2.52449 12.0552 2.76927 12.0552C3.01405 12.0552 3.24894 11.9579 3.42201 11.7848C3.59509 11.6118 3.69234 11.3769 3.69234 11.1321C3.69234 10.8873 3.59509 10.6524 3.42201 10.4793C3.24894 10.3063 3.01405 10.209 2.76927 10.209C2.52449 10.209 2.2896 10.3063 2.11653 10.4793C1.94345 10.6524 1.8462 10.8873 1.8462 11.1321Z"
        fill="#454F5B"
      />
    </Svg>
  );
}

export function FunnelIcon() {
  return (
    <Svg width="22" height="20" viewBox="0 0 22 20" fill="none">
      <Path
        d="M21 1H1L9 10.46V17L13 19V10.46L21 1Z"
        stroke="#454F5B"
        strokeWidth={1.6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export function GreenArrow({color = '#31BC98'}) {
  return (
    <Svg width="12" height="12" viewBox="0 0 16 16" fill="none">
    <Path d="M1 8H15" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <Path d="M8 1L15 8L8 15" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
  );
}

export function CameraIcon() {
  return (
    <Svg width="24" height="20" viewBox="0 0 24 20" fill="none">
      <Path
        d="M23 17C23 17.5304 22.7893 18.0391 22.4142 18.4142C22.0391 18.7893 21.5304 19 21 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V6C1 5.46957 1.21071 4.96086 1.58579 4.58579C1.96086 4.21071 2.46957 4 3 4H7L9 1H15L17 4H21C21.5304 4 22.0391 4.21071 22.4142 4.58579C22.7893 4.96086 23 5.46957 23 6V17Z"
        stroke="#525FB8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M12 15C14.2091 15 16 13.2091 16 11C16 8.79086 14.2091 7 12 7C9.79086 7 8 8.79086 8 11C8 13.2091 9.79086 15 12 15Z"
        stroke="#525FB8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export function Like() {
  return (
    <Svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M13 8.55227V4.23669C13 3.37826 12.6839 2.555 12.1213 1.948C11.5587 1.34101 10.7956 1 10 1L6 10.7101V22.5779H17.28C17.7623 22.5838 18.2304 22.4014 18.5979 22.0644C18.9654 21.7273 19.2077 21.2583 19.28 20.7438L20.66 11.0337C20.7035 10.7245 20.6842 10.4087 20.6033 10.1083C20.5225 9.80791 20.3821 9.53007 20.1919 9.29402C20.0016 9.05798 19.7661 8.86939 19.5016 8.74131C19.2371 8.61323 18.9499 8.54873 18.66 8.55227H13ZM6 22.5779H3C2.46957 22.5779 1.96086 22.3506 1.58579 21.9459C1.21071 21.5412 1 20.9924 1 20.4201V12.8679C1 12.2956 1.21071 11.7467 1.58579 11.3421C1.96086 10.9374 2.46957 10.7101 3 10.7101H6"
        fill="#31BC98"
      />
      <Path
        d="M6 22.5779H3C2.46957 22.5779 1.96086 22.3506 1.58579 21.9459C1.21071 21.5412 1 20.9924 1 20.4201V12.8678C1 12.2956 1.21071 11.7467 1.58579 11.3421C1.96086 10.9374 2.46957 10.7101 3 10.7101H6M13 8.55227V4.23669C13 3.37826 12.6839 2.555 12.1213 1.948C11.5587 1.34101 10.7956 1 10 1L6 10.7101V22.5779H17.28C17.7623 22.5838 18.2304 22.4014 18.5979 22.0644C18.9654 21.7273 19.2077 21.2583 19.28 20.7438L20.66 11.0337C20.7035 10.7245 20.6842 10.4087 20.6033 10.1083C20.5225 9.80791 20.3821 9.53007 20.1919 9.29402C20.0016 9.05798 19.7661 8.86939 19.5016 8.74131C19.2371 8.61323 18.9499 8.54873 18.66 8.55227H13Z"
        stroke="#EAF8F5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export function Dislike() {
  return (
    <Svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M8.68265 14.0004V18.0004C8.68265 18.796 8.99872 19.5591 9.56133 20.1217C10.1239 20.6843 10.887 21.0004 11.6826 21.0004L15.6826 12.0004V1.00036H4.40265C3.92032 0.994909 3.45227 1.16396 3.08474 1.47636C2.71721 1.78875 2.47497 2.22346 2.40265 2.70036L1.02265 11.7004C0.97914 11.987 0.998473 12.2797 1.07931 12.5581C1.16014 12.8365 1.30055 13.0941 1.49079 13.3128C1.68103 13.5316 1.91657 13.7064 2.18108 13.8251C2.44558 13.9439 2.73274 14.0036 3.02265 14.0004H8.68265ZM15.6826 1.00036H18.3526C18.9186 0.990352 19.4685 1.1885 19.8981 1.55718C20.3276 1.92586 20.6068 2.43942 20.6826 3.00036V10.0004C20.6068 10.5613 20.3276 11.0749 19.8981 11.4435C19.4685 11.8122 18.9186 12.0104 18.3526 12.0004H15.6826"
        fill="#F56161"
      />
      <Path
        d="M15.6826 1.00036H18.3526C18.9186 0.990352 19.4685 1.1885 19.8981 1.55718C20.3276 1.92586 20.6068 2.43942 20.6826 3.00036V10.0004C20.6068 10.5613 20.3276 11.0749 19.8981 11.4435C19.4685 11.8122 18.9186 12.0104 18.3526 12.0004H15.6826M8.68265 14.0004V18.0004C8.68265 18.796 8.99872 19.5591 9.56133 20.1217C10.1239 20.6843 10.887 21.0004 11.6826 21.0004L15.6826 12.0004V1.00036H4.40265C3.92032 0.994909 3.45227 1.16396 3.08474 1.47636C2.71721 1.78875 2.47497 2.22346 2.40265 2.70036L1.02265 11.7004C0.97914 11.987 0.998473 12.2797 1.07931 12.5581C1.16014 12.8365 1.30055 13.0941 1.49079 13.3128C1.68103 13.5316 1.91657 13.7064 2.18108 13.8251C2.44558 13.9439 2.73274 14.0036 3.02265 14.0004H8.68265Z"
        stroke="#FDF2F2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
