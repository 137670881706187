import {Dimensions, Alert, Platform, Linking} from 'react-native';
export const IMAGES_SERVER = 'https://inrbonds.com/images/';
export const ORDERS_API = 'https://inrbonds.com/api/orders/';
export const QIDATA_API = 'https://inrbonds.com/api/qiData/';
export const COMMON_API = 'https://inrbonds.com/api/common/';
export const USER_API = 'https://inrbonds.com/api/user/';
export const CUSTOMER_API = 'https://www.inrbonds.com/api/customers/';
export const IPO_API = 'https://inrbonds.com/api/ipo/';
export const TRADES_API = 'https://inrbonds.com/api/trades/';
//export const KYC_API = 'https://inrbonds.com/api/KYC/';
export const QuickInvest = 'https://inrbonds.com/quickInvest';

// export const IMAGES_SERVER = 'https://test.inrbonds.com/images/';
// export const ORDERS_API = 'https://test.inrbonds.com/api/orders/';
// export const QIDATA_API = 'https://test.inrbonds.com/api/qiData/';
// export const COMMON_API = 'https://test.inrbonds.com/api/common/';
// export const USER_API = 'https://test.inrbonds.com/api/user/';
// export const CUSTOMER_API = 'https://test.inrbonds.com/api/customers/';
// export const IPO_API = 'https://test.inrbonds.com/api/ipo/';
// export const TRADES_API = 'https://test.inrbonds.com/api/trades/';
// //export const KYC_API = 'https://test.inrbonds.com/api/KYC/';
// export const QuickInvest = 'https://test.inrbonds.com/quickInvest';
// export const SignUp = 'https://test.inrbonds.com/signup';


// export const BONDS_API = 'http://localhost:6001/bonds/';
// export const ORDERS_API = 'http://localhost:6001/orders/';
// export const QIDATA_API = 'http://localhost:6001/qiData/';
// export const COMMON_API = 'http://localhost:6001/common/';
// export const USER_API = 'http://localhost:6001/user/';
// export const CUSTOMER_API = 'http://localhost:6001/customers/';
// export const IPO_API = 'http://localhost:6001/ipo/';
// export const TRADES_API = 'http://localhost:6001/trades/';
// export const KYC_API = 'http://localhost:6001/KYC/';
// export const MarketWatch = 'http://localhost/marketWatch';
// export const QuickInvest = 'http://localhost/quickInvest';
// export const SignUp = 'https://inrbonds.com/signup';


export const semiBold = 'Inter-SemiBold';
export const regular = 'Inter-Regular';
export const isMobile = Dimensions.get('window').width < 800;
export const screenWidth = Dimensions.get('screen').width;
export const screenHeight = Dimensions.get('screen').height;
export const isNative = Platform.OS !== 'web';

export const dateFormatter = new Intl.DateTimeFormat('en-IN', {
  month: 'short',
  year: 'numeric',
  day: 'numeric',
});
export const currencyFormatter = new Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',
});

export const loginStates = {
  LOGGED_OUT: 0,
  LOGGED_IN: 1,
  LOGGING_IN: 2,
  PENDING_VERIFICATION: 3,
  NEW_PASSWORD: 4,
  FAILED_LOGIN: 5,
};

export const subscriptionOptions = {
  NoSubscription: 0,
  TrainingSubscription: 1,
  ResearchSubscription: 2,
  MarketWatchSubscription: 4,
  QuickInvestSubscription: 8,
  AllSubscriptions: 15,
};

export const Constants = {
  SUCESS: true,
  FAILURE: false,
  FAIL_DB_OP: 'DB operation failed',
  MISSING_REQUIRED_PARAMS: 'Missing required parameters',
  PERMISSION_DENIED: 'Permission Denied',
  SUCESSFUL: 'Sucessful',
  SPECIAL_PRODUCTS: ['B', 'S', 'P'],
  NORMAL_USER_ROLE: 1,
};

export const MinimumInv = (price, facevalue) => {
  const numBonds = Math.ceil(200000 / ((price * facevalue) / 100));
  const minInv = ((price * facevalue) / 100) * numBonds;

  return {minInv: minInv, numBonds: numBonds};
};

export const GlobalErrorHandler = error => {
  if (!isNative) {
    console.error(error);
  }
  GlobalAlert('Oops! Something went wrong. Please try again.');
};

export const GlobalAlert = (message, okCallback, cancelCallback) => {
  if (!isNative) {
    if (okCallback && cancelCallback) {
      if (window.confirm(message)) {
        okCallback();
      } else {
        cancelCallback();
      }
    } else {
      alert(message);
      if (okCallback) { okCallback(); }
    }
  } else {
    let options = null;
    if (okCallback) {
      options = [{text: 'Ok', onPress: okCallback}];
    }
    if (cancelCallback) {
      options = [
        ...options,
        {text: 'Cancel', style: 'cancel', onPress: cancelCallback},
      ];
    }
    Alert.alert('Confirm Action', message, options);
  }
};

export const goToExternalLink = (URL) => {
  Linking.canOpenURL(URL).then((supported) => {
    if (supported) {
      if (URL.toLowerCase().indexOf('inrbonds.com') < 0) {
        GlobalAlert(
          'You are being redirected to an information source which does not belong to INRBonds.',
          () => {
            if (isNative) { Linking.openURL(URL); }
            else { window.location.href = URL; }
          },
        );
      } else {
        if (isNative) { Linking.openURL(URL); }
        else { window.location.href = URL; }
      }
    } else {
      GlobalAlert('Please visit ' + URL);
    }
  });
};
