import React, {useState} from 'react';
import {
  View,
  Pressable,
  Text,
  useWindowDimensions,
  StyleSheet,
  Platform,
} from 'react-native';
import INRLogo from '../assets/images/INRLogo';
import {useNavigation, useRoute} from '@react-navigation/native';
import useGlobalStore from '../utils/GlobalStateStore';
import {isNative} from '../utils/constants';
import Profile from '../screens/profile';
import {
  loginStates,
  isMobile,
  regular,
  semiBold,
  QuickInvest,
  SignUp,
} from '../utils/constants';
import {Back} from './icon';

function OSBack() {
  const persistedUser = useGlobalStore(state => state.persistedUser);
  const navigation = useNavigation();
  const route = useRoute();

  if (Platform.OS === 'ios') {
    if (persistedUser && route.name === 'Listing'){ return null; }
    else if (route.name === 'Home') {
      return null;
    } else {
      return (
        <Pressable
          onPress={() => navigation.goBack()}
          style={{width: 40, height: 30, justifyContent: 'center'}}>
          <Back />
        </Pressable>
      );
    }
  } else { return null; }
}

export default function Header() {
  const navigation = useNavigation();
  const loginStage = useGlobalStore(state => state.loginStage);
  const currentUser = useGlobalStore(state => state.loggedInUser);
  const {width} = useWindowDimensions();
  const [profile, setProfile] = useState(false);
  const loginPossible = ['Home', 'How It Works', 'Listing', 'Detail'];

  //finding current route name
  const route = useRoute();
  const LoginRouteParams = {
    ...route.params,
    nextScreen: isNative ? route.name : encodeURI(window.location.href),
  };


  const navigateProfile = () => setProfile(!profile);

  return (
    <View
      style={{
        width: width,
        height: 90,
        backgroundColor: '#EDF4EC',
        justifyContent: 'center',
        marginTop: Platform.OS === 'ios' ? 20 : 0,
      }}>
      <View
        style={
          isNative
            ? [styles.headerContainer, {width: isMobile ? width - 30 : 1200}]
            : [
                styles.headerContainer,
                {width: isMobile ? width - 30 : 1200, marginTop: 24},
              ]
        }>
        <OSBack />
        <Pressable
          accessibilityRole="link"
          disabled={isNative}
          onPress={() => (window.location = 'https://www.inrbonds.com')}>
          <INRLogo />
        </Pressable>
        <View
          style={
            isMobile ? styles.buttonContainerMobile : styles.buttonContainer
          }>
          {isNative ? null : (
            <>
              <MenuLinkForWeb location={QuickInvest} label="Invest Now" />
              <MenuLinkForWeb
                location="https://www.inrbonds.com/learn"
                label="Learn"
              />
          {/*<MenuLinkForWeb
                 location="https://www.inrbonds.com/partners"
                 label="For Partners"
              />*/}
              <MenuLinkForWeb
                location="https://www.inrbonds.com/contact-us"
                label="Contact Us"
              />
            </>
          )}
          {loginStage === loginStates.LOGGED_IN && currentUser ? (
            <Pressable onPress={navigateProfile}>
              <View style={styles.logo}>
                <Text style={styles.logoText}>{currentUser.firstName[0]}</Text>
              </View>
            </Pressable>
          ) : loginPossible.includes(route.name) ? (
            <>
              <Pressable
                style={isNative ? styles.loginButtonNative : styles.loginButton}
                accessibilityRole="button"
                onPress={() => navigation.navigate('Login', LoginRouteParams)}>
                <Text style={styles.loginText}>Login</Text>
              </Pressable>
              {!isNative && (
                <Pressable
                  style={styles.SignupButton}
                  accessibilityRole="link"
                  onPress={() => window.location = 'https://www.inrbonds.com/signup'}>
                  <Text style={styles.SignupText}>Signup</Text>
                </Pressable>
              )}
            </>
          ) : null}
        </View>
      </View>
      {profile ? (
        <View style={styles.sidebar}>
          <Profile
            user={currentUser}
            onCloseRequest={() => setProfile(!profile)}
          />
        </View>
      ) : null}
    </View>
  );
}

export function MenuLinkForWeb(props) {
  return (
    <Pressable
      style={styles.menuButton}
      accessibilityRole="link"
      onPress={() => (window.location = props.location)}>
      <Text style={styles.linkButton}>{props.label}</Text>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  linkButton: {
    fontFamily: regular,
    fontSize: 16,
    color: '#454F5B',
    fontWeight: '600',
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'center',
    paddingVertical: 10,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    borderRadius: 16,
    alignItems: 'center',
    paddingHorizontal: 24,
    paddingVertical: 12,
    shadowColor: '#000',
    shadowOffset: {
      width: 4,
      height: 4,
    },
    shadowOpacity: 0.6,
    shadowRadius: 6,
    elevation: 6,
  },
  buttonContainerMobile: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  menuButton: {
    marginHorizontal: 16,
  },
  sidebar: {
    position: 'absolute',
    top: 0,
    right: 0,
    maxWidth: 440,
    height: '100%',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.32,
    shadowRadius: 5.46,
    elevation: 9,
  },
  loginButton: {
    backgroundColor: 'white',
    borderColor: '#31BC98',
    borderWidth: 2,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    height: 34,
    alignItems: 'center',
    justifyContent: 'center',
  },
  loginButtonNative: {
    backgroundColor: 'white',
    borderColor: '#31BC98',
    borderWidth: 2,
    borderRadius: 8,
    height: 34,
    alignItems: 'center',
    justifyContent: 'center',
  },
  SignupButton: {
    backgroundColor: '#31BC98',
    borderColor: '#31BC98',
    borderWidth: 2,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    height: 34,
    alignItems: 'center',
    justifyContent: 'center',
  },
  loginText: {
    fontFamily: semiBold,
    fontSize: 16,
    color: '#31BC98',
    paddingHorizontal: 12,
  },
  SignupText: {
    fontFamily: semiBold,
    fontSize: 16,
    color: 'white',
    paddingHorizontal: 12,
  },
  heroPara: {
    fontFamily: regular,
    fontSize: 16,
    color: '#454F5B',
  },
  logo: {
    width: 42,
    height: 42,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
    backgroundColor: 'white',
    borderColor: '#31BC98',
    borderWidth: 2,
    elevation: 4,
  },
  logoText: {
    color: '#454F5B',
    fontSize: 16,
    fontWeight: '600',
  },
});
