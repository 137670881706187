import React from 'react';
import { Pressable, StyleSheet, Linking, Platform } from 'react-native';
import {Svg, Path} from 'react-native-svg';
import validator from 'validator';
import { GlobalAlert } from '../utils/constants';
import { apiService } from '../utils/apiService';

export function Phone() {

  const callNumber = (e) => {
    const now = new Date();
    const isAvailable = now.getHours() >= 10 && now.getHours() <= 17;
    apiService.getCustomerCare().then(phoneNo => {
      if (validator.isMobilePhone(phoneNo)) {
        let phoneNumber = null;
        if (Platform.OS !== 'android') { phoneNumber = `telprompt:${phoneNo}`; }
        else  { phoneNumber = `tel:${phoneNo}`; }
        Linking.canOpenURL(phoneNumber).then(supported => {
          if (!supported || !isAvailable)  { GlobalAlert('You can call us at ' + phoneNo + ' during normal business hours'); }
          else { return Linking.openURL(phoneNumber); }
        }).catch(error => GlobalAlert('You can call us at ' + phoneNo + ' during normal business hours'));
      }
    }).catch(error => {});
  };

  return (
    <Pressable accessibilityRole="button" style={styles.phone} onPress={callNumber}>
      <Svg width="28" height="28" viewBox="0 0 28 28" fill="none">
        <Path d="M26.9992 20.466V24.38C27.0007 24.7434 26.9261 25.1031 26.7802 25.436C26.6344 25.7689 26.4205 26.0678 26.1522 26.3134C25.8839 26.559 25.5672 26.7461 25.2223 26.8625C24.8774 26.9789 24.5119 27.0221 24.1493 26.9894C20.1266 26.5532 16.2626 25.1813 12.8676 22.984C9.70898 20.9809 7.03104 18.3083 5.02394 15.1559C2.81462 11.7523 1.43971 7.8771 1.01061 3.84433C0.977943 3.48355 1.02091 3.11992 1.13676 2.77661C1.25262 2.43331 1.43884 2.11783 1.68355 1.85029C1.92827 1.58274 2.22612 1.36897 2.55815 1.2226C2.89018 1.07623 3.24911 1.00047 3.61208 1.00013H7.5339C8.16833 0.993895 8.78338 1.21811 9.26442 1.63098C9.74546 2.04386 10.0597 2.61721 10.1484 3.24418C10.314 4.49676 10.621 5.72664 11.0635 6.91034C11.2394 7.37732 11.2775 7.88483 11.1732 8.37273C11.069 8.86064 10.8267 9.30849 10.4753 9.66322L8.81503 11.3202C10.676 14.5865 13.3858 17.291 16.6587 19.1483L18.3189 17.4913C18.6743 17.1405 19.1231 16.8988 19.612 16.7947C20.1008 16.6907 20.6093 16.7287 21.0772 16.9042C22.2633 17.3459 23.4956 17.6523 24.7507 17.8175C25.3857 17.9069 25.9657 18.2261 26.3802 18.7145C26.7948 19.2028 27.0151 19.8261 26.9992 20.466Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </Svg>
    </Pressable>
    );
}

const styles = StyleSheet.create({
  phone: {
    backgroundColor: '#31BC98',
    alignItems: 'center',
    borderRadius: 35,
    elevation: 4,
    padding: 12,
    position: 'absolute',
    bottom: 14,
    right: 14,
  },
});
