import React, {useEffect, useState} from 'react';
import {
  View,
  Text,
  ScrollView,
  SafeAreaView,
} from 'react-native';
import LinearBackground from '../../components/linearBackground';
import {BondDetail} from '../../components/bondDetail';
import {InvestSecondary} from '../../components/investment/secondary';
import {InvestPrimary} from '../../components/investment/primary';
import {isMobile, screenHeight} from '../../utils/constants';
import {IssuerLogo} from '../../components/issuerLogo';
import {styles} from './styles';
import { apiService } from '../../utils/apiService';

export const DetailCard = ({route}) => {
  const [selectedBond, setSelectedBond] = useState();
  const primary = route.params.market === 'primary';

  // const handleCardPress = item => navigation.push('Detail', {id: item.isinNo, primary: false});

  useEffect(() => {
    if (primary) { apiService.getIPODetails(route.params.id).then(response => setSelectedBond(response)); }
    else { apiService.getBondDetails(route.params.id).then(response => setSelectedBond(response)); }
  }, [primary]);

  if (!selectedBond) {return null;}
  return (
    <SafeAreaView style={{flex: 1}}>
      <View style={{alignItems: 'center', backgroundColor: 'white'}}>
        <View style={styles.header} >
          <View style={styles.logo}><IssuerLogo name={selectedBond.nameOfIssuer} /></View>
          <View style={{paddingLeft: 10, flex: 1}}>
            <Text style={styles.value}>{selectedBond.nameOfIssuer}</Text>
            <Text style={{fontSize: 14}}>{selectedBond.flags}</Text>
          </View>
        </View>
      </View>
      <ScrollView style={{height: 400}}>
        <LinearBackground style={[styles.background, {height: screenHeight, paddingTop: 20}]} >
          <View style={styles.bondView}>
            <BondDetail bond={selectedBond} primary={primary} />
            <View style={{alignSelf: isMobile ? 'center' : 'flex-start'}}>
              {primary ? <InvestPrimary bond={selectedBond} /> : <InvestSecondary bond={selectedBond} />}
            </View>
          </View>
          {/* {(similarBonds && similarBonds.length > 0) ? (
            <View style={{marginTop: 10, maxWidth: 1100}}>
              <Text style={styles.labelText}>You may also want to consider</Text>
              <View style={styles.similarBonds} >
                {similarBonds.map((item, i) => { return ( <Card bond={item} key={i} onPress={() => handleCardPress(item)} /> ); })}
              </View>
            </View>
          ) : null} */}
        </LinearBackground>
      </ScrollView>
    </SafeAreaView>
  );
};
