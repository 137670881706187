import React from 'react';
import {SafeAreaView, View, ScrollView, Text, Pressable} from 'react-native';

import styles from './styles';
import LinearBackground from '../../components/linearBackground';
import {Phone} from '../../components/phone';
import LowTicketImage from '../../assets/images/low-ticket';
import ResearchImage from '../../assets/images/research';
import {isMobile} from '../../utils/constants';
import PriceImage from '../../assets/images/price';
import ChoiceImage from '../../assets/images/choice';

export const HowItWorks = ({navigation}) => {
  const showListing = () => navigation.navigate('Listing');

  return (
    <>
      <ScrollView style={{height: 400}}>
        <LinearBackground style={styles.container}>
          <SafeAreaView>
            <View style={styles.heroSection}>
              <Text style={styles.heroText}>What we offer</Text>
              <Text style={styles.heroPara}>
                Customers get the benefit of rich expertise, research and
                analytics when using INRBonds
              </Text>
            </View>
            <View style={styles.featureSectionHow}>
              <View style={styles.featureImageHow}>
                <PriceImage />
              </View>
              <Text style={styles.featureTextHow}>Fair price</Text>
            </View>
            <View style={styles.featureSectionHow}>
              <View style={styles.featureImageHow}>
                <LowTicketImage />
              </View>
              <Text style={styles.featureTextHow}>Low ticket size</Text>
            </View>
            <View style={styles.featureSectionHow}>
              <View style={styles.featureImageHow}>
                <ResearchImage />
              </View>
              <Text style={styles.featureTextHow}>Researched information</Text>
            </View>
            <View style={styles.featureSectionHow}>
              <View style={styles.featureImageHow}>
                <ChoiceImage />
              </View>
              <Text style={styles.featureTextHow}>
                A variety of bonds to choose from
              </Text>
            </View>
            <Pressable
              style={{width: 300, alignItems: 'center', alignSelf: 'center'}}
              onPress={showListing}>
              <LinearBackground
                style={styles.heroButton}
                colors={['#31BC98', '#4DBC31']}>
                <Text style={styles.discoverButtonText}>Invest Now</Text>
              </LinearBackground>
            </Pressable>
          </SafeAreaView>
        </LinearBackground>
      </ScrollView>
      {isMobile ? <Phone /> : null}
    </>
  );
};
