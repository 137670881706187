import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {
  semiBold,
  regular,
  dateFormatter,
  currencyFormatter,
} from '../../utils/constants';

export class DealSlipSecondary extends React.PureComponent {
  render() {
    const selectedBond = this.props.selectedBond;
    const selectedOrder = this.props.selectedOrder;
    const quantity = this.props.quantity;
    const dealValue =
      ((selectedOrder.pricePerHundred * selectedBond.faceValue) / 100) *
      parseInt(quantity, 10);
    const accruedInterest =
      selectedBond.accruedInterest * parseInt(quantity, 10);
    const stampDuty = Math.round(
      ((dealValue + accruedInterest) * 0.0001) / 100,
    );

    return (
      <>
        <Item label="Issuer" value={selectedBond.nameOfIssuer} />
        <Item label="ISIN" value={selectedBond.body.ISIN} />
        <Item label="Rating" value={selectedBond.body.Rating} />
        <Item label="Coupon" value={selectedBond.body.Coupon} />
        <Item
          label="Interest Payment Dates"
          value={selectedBond.body['Interest Payment']}
        />
        <Item
          label="Redemption Date"
          value={selectedBond.body['Maturity Date']}
        />
        <Item
          label="Put/Call Date"
          value={
            selectedBond.body['Call Date']
              ? selectedBond.body['Call Date']
              : 'N/A'
          }
        />
        <Item
          label="Price per Hundred"
          value={currencyFormatter.format(selectedOrder.pricePerHundred)}
        />
        <Item label="Number of Bonds" value={quantity} />
        {selectedBond.fromDate === null ? null : (
          <Item
            label="Interest From"
            value={dateFormatter.format(new Date(selectedBond.fromDate))}
          />
        )}
        <Item
          label="To Settlement Date"
          value={dateFormatter.format(new Date(selectedBond.settlementDate))}
        />
        {selectedBond.fromDate === null ? null : (
          <Item label="No of Days" value={selectedBond.numDays} />
        )}
        <Item label="Deal Value" value={currencyFormatter.format(dealValue)} />
        <Item
          label="Accured Interest"
          value={currencyFormatter.format(accruedInterest)}
        />
        <Item
          label="Settlement Amount"
          value={currencyFormatter.format(dealValue + accruedInterest)}
        />
        <Item label="Stamp Duty" value={currencyFormatter.format(stampDuty)} />
      </>
    );
  }
}

class Item extends React.PureComponent {
  render() {
    return (
      <View style={styles.item}>
        <Text style={styles.itemLabel}>{this.props.label}</Text>
        <Text style={styles.itemValue}>{this.props.value}</Text>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  item: {
    flexDirection: 'row',
    paddingVertical: 4,
    backgroundColor: '#F6F6F6',
    marginVertical: 2,
    paddingHorizontal: 10,
  },
  itemLabel: {
    fontFamily: regular,
    fontSize: 14,
    color: '#637381',
    width: '50%',
  },
  itemValue: {
    fontFamily: semiBold,
    fontSize: 14,
    color: '#454F5B',
    width: '50%',
  },
});
