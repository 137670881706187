import {StyleSheet} from 'react-native';
import {
  semiBold,
  isMobile,
  regular,
  screenWidth,
  screenHeight,
} from '../../utils/constants';

const textColor = '#1B2A3C';
const paraColor = '#454F5B';

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#F5F4F2',
    height: '100%',
    paddingBottom: 20,
  },
  containerUpdate: {
    backgroundColor: '#F5F4F2',
    height: screenHeight,
    paddingBottom: 20,
  },
  discoverButton: {
    backgroundColor: '#525FB8',
    width: 275,
    height: 45,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 16,
    elevation: 4,
    marginTop: 10,
  },
  heroText: {
    fontFamily: semiBold,
    fontSize: isMobile ? 36 : 50,
    color: textColor,
    maxWidth: 400,
    marginTop: isMobile ? 26 : 0,
  },
  heroPara: {
    fontFamily: regular,
    fontSize: isMobile ? 18 : 24,
    color: paraColor,
    marginTop: 12,
    maxWidth: 360,
    lineHeight: 27,
  },
  heroButton: {
    height: 45,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
    marginTop: 20,
    paddingHorizontal: 10,
  },
  discoverButtonText: {
    color: 'white',
    fontSize: 18,
    fontFamily: semiBold,
    marginHorizontal: 10,
  },
  heroSection: {
    width: isMobile ? '100%' : 1200,
    paddingTop: isMobile ? 10 : 98,
    alignSelf: 'center',
    paddingBottom: isMobile ? 0 : 100,
    marginBottom: 20,
    paddingHorizontal: isMobile ? 20 : 0,
    flexDirection: isMobile ? 'column' : 'row',
    justifyContent: isMobile ? 'center' : 'space-between',
    alignItems: 'center',
  },
  heroLeft: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  heroRight: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: isMobile ? 10 : 0,
    marginTop: isMobile ? 14 : 0,
  },
  featureSection: {
    flexDirection: 'row',
    width: isMobile ? '100%' : 1100,
    maxWidth: isMobile ? screenWidth : 980,
    paddingHorizontal: 14,
    marginVertical: isMobile ? 0 : 30,
    alignItems: 'center',
    alignSelf: 'center',
  },
  featureImage: {
    width: '40%',
  },
  featureTextSection: {
    width: '60%',
    paddingLeft: 20,
  },
  featureTextSectionLeft: {
    width: '60%',
    paddingRight: 20,
  },
  featureText: {
    fontFamily: semiBold,
    fontSize: isMobile ? 20 : 36,
    color: textColor,
  },
  appStoreText: {
    fontFamily: semiBold,
    fontSize: isMobile ? 18 : 26,
    color: textColor,
    marginBottom: 20,
  },
  featureSectionHow: {
    flexDirection: 'row',
    minWidth: isMobile ? '100%' : 980,
    paddingHorizontal: isMobile ? 20 : 0,
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginVertical: 6,
  },
  featureImageHow: {
    width: isMobile ? '40%' : 443,
    height: isMobile ? 110 : 'auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageStyle: {width: '100%'},
  featureTextHow: {
    fontFamily: semiBold,
    fontSize: isMobile ? 20 : 36,
    color: textColor,
    maxWidth: isMobile ? '60%' : 400,
    marginLeft: isMobile ? 10 : 0,
  },
  featureTextWhite: {
    fontFamily: semiBold,
    fontSize: 36,
    color: 'white',
    maxWidth: 400,
  },
  quote: {
    fontSize: isMobile ? 24 : 30,
    fontFamily: regular,
    color: '#1B2A3C',
    marginTop: isMobile ? 40 : 100,
    marginBottom: isMobile ? 40 : 100,
    lineHeight: isMobile ? 35 : 40,
    paddingHorizontal: isMobile ? 15 : 0,
  },
  whoIsSection: {
    alignSelf: 'center',
    width: 1200,
    alignItems: 'center',
    paddingBottom: 44,
  },
  whoIsRow: {
    flexDirection: isMobile ? 'column' : 'row',
    width: isMobile ? screenWidth : '100%',
  },
  whoIsText: {
    fontFamily: semiBold,
    fontSize: isMobile ? 30 : 36,
    color: textColor,
    marginBottom: 57,
    maxWidth: isMobile ? screenWidth : '100%',
    paddingHorizontal: 15,
  },
  whoIsDescription: {
    fontSize: 20,
    fontFamily: regular,
    color: paraColor,
    lineHeight: 30,
    textAlign: 'center',
    marginTop: 18,
    marginBottom: isMobile ? 30 : 0,
  },
  whoIsImage: {
    width: 245,
    height: 190,
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  whoIsItem: {flex: 1, alignItems: 'center', paddingHorizontal: 10},
  icon: {
    width: 60,
    height: 60,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#31BC98',
    borderRadius: 50,
    elevation: 6,
    position: 'absolute',
    right: 14,
    bottom: 14,
  },
});

export default styles;
