import React, {useState, useEffect} from 'react';
import {
  View,
  Text,
  Pressable,
  ScrollView,
  useWindowDimensions,
} from 'react-native';
import {styles} from './styles';
import LinearBackground from '../../components/linearBackground';
import {WebContainer} from '.';
import {Back} from '../../components/icon';
import {useNavigation} from '@react-navigation/native';
import { isNative, dateFormatter} from '../../utils/constants';
import { apiService } from '../../utils/apiService';

export default function Portfolio() {
  const navigation = useNavigation();
  const [transactions, setTransactions] = useState([]);
  const {height} = useWindowDimensions();

  useEffect(() => apiService.getMyTrades().then(result => setTransactions(result)), []);

  return (
    <View style={{flex: 1}}>
      <LinearBackground style={{height: height - 40}}>
        <ScrollView>
          <View style={[styles.container, {marginBottom: 60}]}>
            <WebContainer width={600}>
              {isNative && (
                <Pressable
                  accessibilityRole="button"
                  onPress={() => navigation.goBack()}
                >
                  <Back color="#637381" />
                </Pressable>
              )}
              {transactions && transactions.length > 0 ? (
                transactions.map((item, i) => (
                  <PortfolioItem key={i} data={item} />
                ))
              ) : (
                <Text style={{marginTop : 40, alignSelf: 'center'}}>You dont have any transactions</Text>
              )}
            </WebContainer>
          </View>
        </ScrollView>
      </LinearBackground>
    </View>
  );
}

export function PortfolioItem({data}) {
  const [show, setShow] = useState(false);
  return (
    <View style={styles.orderSection}>
      <View style={{flex: 1}}>
        <Text style={styles.smallText}>
          {dateFormatter.format(new Date(data.date))}
        </Text>
        <Text style={styles.orderName}>{data.nameOfIssuer} ({data.isinNo})</Text>

        {Object.entries(data.header).map(([key, value]) => {
          if (!!key && !!value) { return (<RowItem name={key} value={value} />); }
          else { return null; }
        })}
        {show && Object.entries(data.body).map(([key, value]) => {
          if (!!key && !!value) { return (<RowItem name={key} value={value} /> ); }
          else { return null; }
        })}
        <Pressable style={styles.more} onPress={() => setShow(!show)}>
          <Text style={styles.moreText}>{show ? 'Less' : 'More'}</Text>
        </Pressable>
      </View>
    </View>
  );
}

export function RowItem({name = '', value = ''}) {
  return (
    <View style={styles.rowItem}>
      <Text style={styles.rowKey}>{name}</Text>
      <Text style={styles.rowValue}>{value}</Text>
    </View>
  );
}
