import React from 'react';
import {
  SafeAreaView,
  View,
  ScrollView,
  Text,
  Pressable,
  Platform,
  StyleSheet,
} from 'react-native';
import LinearBackground from '../components/linearBackground';
import {Phone} from '../components/phone';
import {
  semiBold,
  isMobile,
  regular,
  screenHeight,
  goToExternalLink,
} from '../utils/constants';

export const Update = () => {
  const AppLink = () => {
    if (Platform.OS === 'ios') { return 'https://apps.apple.com/in/app/quickinvest-by-inrbonds/id1604873255'; }
    if (Platform.OS === 'android') { return 'https://play.google.com/store/apps/details?id=com.quickinvest.inrbonds'; }
  };

  const handleClick = () => { goToExternalLink(AppLink()); };

  return (
    <>
      <ScrollView style={{height: 400}}>
        <LinearBackground style={styles.containerUpdate}>
          <SafeAreaView>
            <View style={styles.heroSection}>
              <Text style={styles.heroText}>Update required</Text>
              <Text style={styles.heroPara}>Quickinvest app needs an update. To use the app, please update</Text>
            </View>
            <Pressable style={styles.discoverButton} onPress={handleClick} >
              <LinearBackground style={styles.heroButton} colors={['#31BC98', '#4DBC31']} >
                <Text style={styles.discoverButtonText}>Update Now</Text>
              </LinearBackground>
            </Pressable>
          </SafeAreaView>
        </LinearBackground>
      </ScrollView>
      {isMobile ? <Phone /> : null}
    </>
  );
};

const textColor = '#1B2A3C';
const paraColor = '#454F5B';

const styles = StyleSheet.create({
  containerUpdate: {
    backgroundColor: '#F5F4F2',
    height: screenHeight,
    paddingBottom: 20,
  },
  heroText: {
    fontFamily: semiBold,
    fontSize: isMobile ? 36 : 50,
    color: textColor,
    maxWidth: 400,
    marginTop: isMobile ? 26 : 0,
  },
  heroPara: {
    fontFamily: regular,
    fontSize: isMobile ? 18 : 24,
    color: paraColor,
    marginTop: 12,
    maxWidth: 360,
    lineHeight: 27,
  },
  heroButton: {
    height: 45,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
    marginTop: 20,
    paddingHorizontal: 10,
  },
  discoverButtonText: {
    color: 'white',
    fontSize: 18,
    fontFamily: semiBold,
    marginHorizontal: 10,
  },
  discoverButton: {
    width: 300,
    alignItems: 'center',
    alignSelf: 'center',
  },
  heroSection: {
    width: isMobile ? '100%' : 1200,
    paddingTop: isMobile ? 10 : 98,
    alignSelf: 'center',
    paddingBottom: isMobile ? 0 : 100,
    marginBottom: 20,
    paddingHorizontal: isMobile ? 20 : 0,
    flexDirection: isMobile ? 'column' : 'row',
    justifyContent: isMobile ? 'center' : 'space-between',
    alignItems: 'center',
  },
});
