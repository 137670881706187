import React, {useState, forwardRef, useImperativeHandle} from 'react';
import {StyleSheet, Text, TextInput, View} from 'react-native';
import {semiBold, regular, isMobile} from '../../utils/constants';

export const DealSlipPrimary = forwardRef((props, _ref) => {
  const applications = props.applications;
  const currentUser = props.currentUser;
  const [firstName, setFirstName] = useState(currentUser && currentUser.firstName);
  const [lastName, setLastName] = useState(currentUser && currentUser.lastName);
  const [email, setEmail] = useState(currentUser && currentUser.userName);
  const [phoneNo, setPhoneNo] = useState(currentUser && currentUser.phoneNo);
  const [upiId, setupiId] = useState('');
  const [pan, setPAN] = useState(currentUser && currentUser.PAN);
  const [dpId, setDpId] = useState('');
  const [clientId, setClientId] = useState('');

  useImperativeHandle(_ref, () => ({getFormData: () => {
    return {
    firstName: firstName,
    lastName: lastName,
    email: email,
    phoneNo: phoneNo,
    upiId: upiId,
    pan: pan,
    dpId: dpId,
    clientId: clientId,
  };}}));

  return (
    <>
    <View style={csStyle.cartSummary}>
      {applications && applications.map((d, i) => { return (
        <View style={csStyle.top}>
          <Text style={csStyle.name}>{d.name}</Text>
          <View style={csStyle.quantity}>
            <Text style={csStyle.value}>{d.quantity}</Text>
            <Text style={csStyle.text}> Bonds with </Text>
            <Text style={csStyle.value}>{d['Interest Payment']}</Text>
            <Text style={csStyle.text}> interest at </Text>
            <Text style={csStyle.value}>{d.Coupon} PA</Text>
            <Text style={csStyle.text}> up to </Text>
            <Text style={csStyle.value}>{d.Maturity}</Text>
          </View>
        </View>
      );})}
    </View>
    <View style={style.dealSlip}>
      <View style={style.row}>
        <View style={style.inputGroup}>
          <Text style={style.label}>First Name</Text>
          <TextInput
            style={style.input}
            placeholder="First Name"
            placeholderTextColor={style.placeholder}
            value={firstName}
            onChangeText={setFirstName}
          />
        </View>
        <View style={style.spacer} />
        <View style={style.inputGroup}>
          <Text style={style.label}>Last Name</Text>
          <TextInput
            style={style.input}
            placeholder="Last Name"
            placeholderTextColor={style.placeholder}
            value={lastName}
            onChangeText={setLastName}
          />
        </View>
      </View>
      <View style={style.row}>
        <View style={style.inputGroup}>
          <Text style={style.label}>Mobile number</Text>
          <TextInput
            style={style.input}
            placeholder="Mobile Number"
            placeholderTextColor={style.placeholder}
            value={phoneNo}
            onChangeText={setPhoneNo}
            keyboardType={'numeric'}
          />
        </View>
        <View style={{width: 8}} />
        <View style={style.inputGroup}>
          <Text style={style.label}>Email ID</Text>
          <TextInput
            style={style.input}
            placeholder="Email ID"
            placeholderTextColor={style.placeholder}
            value={email}
            onChangeText={setEmail}
          />
        </View>
      </View>
      {(props.listing === 'exchange') ? (
        <>
          <View style={style.row}>
            <View style={style.inputGroup}>
              <Text style={style.label}>UPI Id</Text>
              <TextInput
                style={style.input}
                placeholder="UPI Id"
                placeholderTextColor={style.placeholder}
                value={upiId}
                onChangeText={setupiId}
              />
            </View>
            <View style={style.spacer} />
            <View style={style.inputGroup}>
              <Text style={style.label}>PAN number</Text>
              <TextInput
                style={style.input}
                placeholder="PAN"
                placeholderTextColor={style.placeholder}
                value={pan}
                onChangeText={setPAN}
              />
            </View>
          </View>
          <View style={style.row}>
            <View style={style.inputGroup}>
              <Text style={style.label}>DP Id</Text>
              <TextInput
                style={style.input}
                placeholder="DP Id"
                placeholderTextColor={style.placeholder}
                value={dpId}
                onChangeText={setDpId}
                keyboardType={'numeric'}
              />
            </View>
            <View style={style.spacer} />
            <View style={style.inputGroup}>
              <Text style={style.label}>Client Id</Text>
              <TextInput
                style={style.input}
                placeholder="Client Id"
                placeholderTextColor={style.placeholder}
                value={clientId}
                onChangeText={setClientId}
                keyboardType={'numeric'}
              />
            </View>
          </View>
        </>
      ) : null}
    </View>
    </>
  );
});

const csStyle = StyleSheet.create({
  cartSummary: {
    padding: 12,
    shadowColor: 'black',
    shadowOpacity: 0.2,
    shadowRadius: 4,
    shadowOffset: {
      width: 2,
      height: 2,
    },
    elevation: 3,
    borderWidth: 1,
    borderColor: '#E0E0E0',
    marginVertical: 8,
    backgroundColor: '#FEFFF2',
  },
  top: {
    flexDirection: 'row',
    marginBottom: 8,
  },
  name: {
    fontFamily: semiBold,
    fontSize: 14,
    color: '#637381',
    marginRight: 28,
    alignItems: 'center',
  },
  quantity: {
    flexDirection: isMobile ? 'column' : 'row',
    paddingHorizontal: 12,
    borderLeftWidth: 1,
    borderColor: '#E8E6E2',
    alignItems: isMobile ? 'flex-start' : 'center',
  },
  text: {
    fontFamily: regular,
    fontSize: 14,
    color: '#637381',
    marginRight: 4,
  },
  value: {
    fontFamily: semiBold,
    fontSize: 14,
    color: '#132538',
    marginRight: 4,
  },
});

const style = StyleSheet.create({
  dealSlip: {
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    paddingHorizontal: 19,
    paddingVertical: 22,
    marginTop: 10,
    borderRadius: 10,
    width: isMobile ? '100%' : 800,
  },
  inputGroup: {
    marginBottom: 16,
    flex: 1,
  },
  label: {
    fontFamily: regular,
    fontSize: 12,
    color: '#637381',
    marginBottom: 12,
  },
  input: {
    height: 40,
    paddingHorizontal: 14,
    paddingVertical: 11,
    fontFamily: semiBold,
    fontSize: 14,
    color: '#383E56',
    borderWidth: 1,
    borderColor: '#DFE3E8',
    borderRadius: 4,
  },
  buyButton: {
    backgroundColor: '#27AE60',
    height: 45,
    borderRadius: 24,
    alignItems: 'center',
    justifyContent: 'center',
    elevation: 4,
    maxWidth: 320,
    alignSelf: 'center',
    width: 280,
  },
  row : {flexDirection: isMobile ? 'column' : 'row',
},
  spacer : isMobile ? {display : 'none'} : {width : 8},
});
