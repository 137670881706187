import React from 'react';
import {StyleSheet, Text, View, Pressable} from 'react-native';

import {isMobile} from '../utils/constants';
import {ScoreBoard, DemoScoreBoard} from './scoreboard';
import {IssuerLogo} from './issuerLogo';

export class Card extends React.PureComponent {
  render() {
    const bond = this.props.bond;
    const primary = this.props.isPrimary;
    const message = !this.props.loggedIn ? 'Sign In to see the score' : null;
    const amount = new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
    }).format(
      primary
        ? bond.minQty * bond.faceValue
        : (bond.bestOffer.pricePerHundred *
            bond.faceValue *
            bond.bestOffer.quantity) /
            100,
      // : MinimumInv(bond.bestOffer.pricePerHundred, bond.faceValue).minInv,
    );
    return (
      <Pressable
        style={styles.card}
        accessibilityRole="button"
        onPress={this.props.onPress}>
        <View style={styles.top}>
          <View style={{backgroundColor: '#F1F1F1', borderRadius: 50}}>
            <IssuerLogo name={bond.nameOfIssuer} />
          </View>
          <View style={styles.titleSection}>
            <Text style={styles.title}>{bond.nameOfIssuer}</Text>
            <Text style={styles.subTitle}>{bond.flags}</Text>
          </View>
        </View>
        <View style={styles.middle}>
          {Object.entries(bond.body).map(([key, value]) => {
            if (!!key && !!value) {
              return (
                <View style={styles.item} key={key}>
                  <Text style={styles.label}>{key}</Text>
                  <Text
                    style={[styles.value, {color: '#334756', maxWidth: '60%'}]}>
                    {value}
                  </Text>
                </View>
              );
            } else {
              return null;
            }
          })}
        </View>
        <View style={styles.bottom}>
          <View style={styles.row}>
            <View>
              <Text style={styles.label}>
                {primary ? 'Expected Returns' : 'Best Offer'}
              </Text>
              <Text style={styles.offer}>
                {primary ? bond.ytmRange : bond.bestOffer.ytm}
              </Text>
            </View>
            <View style={{alignItems: 'flex-end'}}>
              <Text style={styles.label}>Minimum Investment</Text>
              <Text style={styles.price}> {amount} </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: 10,
            }}>
                {/* <View
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start',
                flex: 1,
              }}>
              {bond.creditSpreadScore && bond.creditSpreadScore > 0 ? (
                <>
                  <Text style={styles.label}>INRBonds Risk Scale</Text>
                  {message ? (
                    <DemoScoreBoard message={message} />
                  ) : (
                    <ScoreBoard score={bond.creditSpreadScore} />
                  )}
                </>
              ) : null}
            </View> */}
            <View style={styles.button}>
              <Text style={styles.buttonText}>Invest Now</Text>
            </View>
          </View>
        </View>
      </Pressable>
    );
  }
}

const styles = StyleSheet.create({
  card: {
    backgroundColor: '#FFF',
    shadowColor: 'black',
    shadowOpacity: 0.2,
    shadowRadius: 4,
    shadowOffset: {
      width: 2,
      height: 2,
    },
    borderRadius: 8,
    paddingHorizontal: 12,
    paddingVertical: 10,
    elevation: 4,
    width: isMobile ? '100%' : 400,
    marginTop: 5,
    marginBottom: 5,
    marginHorizontal: isMobile ? 0 : 5,
  },
  top: {
    paddingBottom: 6,
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 80,
  },
  middle: {
    borderBottomColor: '#F4F6F8',
    borderTopColor: '#F4F6F8',
    borderLeftColor: 'white',
    borderRightColor: 'white',
    borderWidth: 1,
    paddingVertical: 6,
  },
  bottom: {
    paddingVertical: 6,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  risk: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 6,
    marginTop: 5,
    borderColor: 'white',
    borderTopColor: '#F4F6F8',
    borderWidth: 1,
  },
  logo: {
    width: 45,
    height: 45,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
    backgroundColor: '#40B3BA',
  },
  logoText: {
    color: 'white',
    fontSize: 18,
    fontWeight: '600',
  },
  titleSection: {
    paddingLeft: 18,
    flex: 1,
  },
  item: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 3,
  },
  label: {
    color: '#637381',
    fontSize: 14,
    fontFamily: 'Inter-Regular',
  },
  value: {
    fontSize: 14,
    fontFamily: 'Inter-SemiBold',
  },
  price: {
    fontSize: 14,
    fontFamily: 'Inter-SemiBold',
    color: '#454F5B',
  },
  offer: {
    fontSize: 14,
    fontFamily: 'Inter-SemiBold',
    color: '#57CC99',
  },
  title: {
    color: '#637381',
    fontSize: 16,
    fontFamily: 'Inter-SemiBold',
  },
  subTitle: {
    color: '#637381',
    fontSize: 14,
    fontFamily: 'Inter-Medium',
  },
  button: {
    backgroundColor: '#31BC98',
    paddingVertical: 4,
    paddingHorizontal: 12,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-end',
    borderRadius: 8,
    elevation: 3,
  },
  buttonText: {
    fontSize: 16,
    padding: 2,
    color: 'white',
    fontWeight: '600',
    textAlign: 'center',
  },
});
