import create from 'zustand';
import {loginStates} from './constants';

const useGlobalStore = create((set, get) => ({
  loginStage: loginStates.LOGGING_IN,
  loggedInUser: null,
  persistedUser: null,

  setloginStage: stage => {
    set(state => ({loginStage: stage}));
    if (stage !== loginStates.LOGGED_IN) {
      set(state => ({loggedInUser: null}));
    }
  },
  setLoggedInUser: user => {set(state => ({loggedInUser: user}));},
  setPersistedUser: user => set(state => ({persistedUser: user})),
}));

export default useGlobalStore;
