import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  main: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F9FAFB',
  },
  logo: {
    overflow: 'hidden',
  },
  container: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 325,
    backgroundColor: 'white',
    elevation: 5,
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 0.1,
    shadowRadius: 5,
    borderRadius: 8,
    paddingVertical: 20,
  },
  h4: {
    fontWeight: '600',
    fontSize: 18,
    lineHeight: 22,
    marginTop: 6,
    marginBottom: 6,
  },
  input: {
    height: 50,
    width: 300,
    paddingLeft: 10,
    margin: 10,
    borderWidth: 1,
    borderColor: '#E0E0E0',
    borderRadius: 5,
    color: '#454F5B',
  },
  userID: {
    fontWeight: '500',
    fontSize: 16,
    paddingLeft: 10,
    margin: 10,
    justifyContent: 'center',
  },
  errorText: {
    fontWeight: '200',
    fontSize: 12,
    color: 'red',
    lineHeight: 22,
  },
  messageText: {
    fontWeight: '400',
    fontSize: 14,
    color: '#808080',
    borderRadius: 6,
  },
  changeUser: {
    fontWeight: '400',
    fontSize: 15,
    color: '#232328',
    marginBottom: 10,
  },
  subscribeText: {
    fontWeight: '500',
    fontSize: 15,
    color: '#101010',
    margin: 10,
    marginLeft: 2,
  },
  loginButton: {
    backgroundColor: '#31bc98',
    borderRadius: 8,
    borderWidth: 2,
    borderColor: '#31bc98',
    justifyContent: 'center',
    alignItems: 'center',
    height: 38,
    width: 100,
    margin: 10,
  },
  login: {
    color: 'white',
    fontSize: 16,
    fontWeight: '500',
  },
  submitContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  OTPbutton: {
    marginLeft: 8,
  },
});
